<i18n>
ru:
  add: Добавить
  addToOrder: Добавить к заказу
  itemPrice: '{price}/шт'
  itemPriceFree: 'Бесплатно {free} шт, более — {price}/шт'
ua:
  add: Додати
  addToOrder: Додати до замовлення
  itemPrice: '{price}/шт'
  itemPriceFree: '{free} шт безкоштовно, більше — {price}/шт'
us:
  add: Add
  addToOrder: Add to order
  itemPrice: '{price}/each'
  itemPriceFree: '{free} item free, additional items — {price}/each'
</i18n>

<template>
  <div
    class="v-additional-items-wrapper"
    v-if="
      clientStore.ClientState.data &&
      clientStore.ClientState.data.AdditionalProduct.length > 0
    "
  >
    <div
      class="v-typography-title-4 v-additional-items-wrapper__title"
      v-html="translate('additionalItems.addToOrder')"
    />
    <div class="v-additional-items-wrapper__divider">
      <div
        class="v-additional-item v-d-flex v-flex-row"
        :key="additionalProduct.AdditionalProductId"
        v-for="(additionalProduct, index) in clientStore.ClientState.data
          .AdditionalProduct"
      >
        <div class="v-flex-grow-1 v-d-flex v-flex-column v-justify-content-end">
          <div
            class="v-typography-title-5"
            v-html="sanitize(additionalProduct.Product.Name)"
          />
          <i18n-t
            class="v-d-flex v-typography-text-5 v-color-mono-600 v-additional-item__price"
            :keypath="
              additionalProduct.FreeCount > 0
                ? 'additionalItems.itemPriceFree'
                : 'additionalItems.itemPrice'
            "
            scope="global"
            tag="div"
          >
            <template #free>{{ additionalProduct.FreeCount }} </template>
            <template #price>
              <common-currency :amount="additionalProduct.Product.Price" />
            </template>
          </i18n-t>
        </div>

        <div
          class="v-elements-overlay v-additional-item__button v-d-flex v-justify-content-end"
        >
          <AnimatePresence>
            <motion.div
              v-if="
                cartItemPerAdditionalProduct[additionalProduct.AdditionalProductId]
              "
              class="v-overlay-element"
              :key="`${additionalProduct.Product.ID}-stepper`"
              :initial="{ opacity: 0 }"
              :animate="{ opacity: 1 }"
              :exit="{ opacity: 0 }"
            >
              <ui-stepper
                :value="
                  cartItemPerAdditionalProduct[
                    additionalProduct.AdditionalProductId
                  ]!.RealCount
                "
                :size="32"
                color="grey"
                :on-decrement="
                  async () => await decrement(additionalProduct.Product)
                "
                :on-increment="
                  async () => await increment(additionalProduct.Product)
                "
              />
            </motion.div>
            <motion.div
              v-else
              :key="`${additionalProduct.Product.ID}-button`"
              class="v-overlay-element"
              :initial="{ opacity: 0 }"
              :animate="{ opacity: 1 }"
              :exit="{ opacity: 0 }"
            >
              <ui-button
                :size="32"
                color="primary-opacity"
                width="wide"
                :label="translate('additionalItems.add')"
                :click-event="async () => await increment(additionalProduct.Product)"
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <div
          class="v-w-100 v-additional-item__hr"
          v-if="index < clientStore.ClientState.data.AdditionalProduct.length - 1"
        >
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'
import type { ProductInfo } from '~types/common'

import { type GUID } from '@arora/common'

import { motion } from 'motion-v'
import { FirstAddType } from '~api/consts'

const clientStore = useClientStore()

const { sanitize, translate } = useI18nSanitized()
const { addToCart, removeCartItem, setItemCount } = useCartFunctionsWithDialogs()

const cartItemPerAdditionalProduct = ref<Record<GUID, CartItem | undefined>>({})

function updateProductsRecord(): void {
  const content = clientStore.ClientState.data?.Cart?.Content ?? []
  cartItemPerAdditionalProduct.value = {}
  for (const additionalProduct of clientStore.ClientState.data?.AdditionalProduct ??
    []) {
    cartItemPerAdditionalProduct.value[additionalProduct.AdditionalProductId] =
      content.find(
        (product) => product.ProductID === additionalProduct.AdditionalProductId
      )
  }
}

async function increment(product: ProductInfo): Promise<void> {
  if (cartItemPerAdditionalProduct.value[product.ID]) {
    const count: number = cartItemPerAdditionalProduct.value[product.ID]!.RealCount
    await (count === 0
      ? addToCart(
          product.ID,
          FirstAddType.Additional,
          product.GroupID,
          product.Name,
          product.Price
        )
      : setItemCount(
          cartItemPerAdditionalProduct.value[product.ID]!.ID,
          count + 1,
          cartItemPerAdditionalProduct.value[product.ID]!.GroupID,
          cartItemPerAdditionalProduct.value[product.ID]!.Name
        ))
  } else {
    await addToCart(
      product.ID,
      FirstAddType.Additional,
      product.GroupID,
      product.Name,
      product.Price
    )
  }
}

async function decrement(product: ProductInfo): Promise<void> {
  if (cartItemPerAdditionalProduct.value[product.ID]) {
    const count: number = cartItemPerAdditionalProduct.value[product.ID]!.RealCount
    if (count === 1) {
      await removeCartItem(
        cartItemPerAdditionalProduct.value[product.ID]!.ID,
        count - 1,
        cartItemPerAdditionalProduct.value[product.ID]!.GroupID,
        cartItemPerAdditionalProduct.value[product.ID]!.Name
      )
    } else if (count !== 0) {
      await setItemCount(
        cartItemPerAdditionalProduct.value[product.ID]!.ID,
        count - 1,
        cartItemPerAdditionalProduct.value[product.ID]!.GroupID,
        cartItemPerAdditionalProduct.value[product.ID]!.Name
      )
    }
  }
}

onMounted(() => updateProductsRecord())

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  async (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) {
      updateProductsRecord()
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/extend';
@use 'assets/variables';

.v-additional-items-wrapper {
  &__title {
    margin: 1.715rem 0 0.855rem 1.715rem;
  }

  &__divider {
    padding-top: 0.855rem;
    padding-bottom: 0.855rem;

    background: variables.$White;

    .v-additional-item {
      padding: 0.855rem 0.855rem 0 1.715rem;

      &__hr {
        @extend .v-no-hr-margin;
      }

      &__price {
        margin-bottom: 1.5rem;
      }

      &__button {
        width: 7.855rem;
        margin: 0.855rem 0;

        .v-overlay-element {
          width: 7.855rem;
          height: 32px;
        }
      }
    }
  }
}
</style>
