<template>
  <motion.ul class="v-cart-sidebar-items-wrapper">
    <AnimatePresence>
      <motion.li
        v-for="item in items"
        :key="`cart-item-${item.ID}`"
        class="v-cart-sidebar-item"
        :initial="{ opacity: 0, scaleY: 0 }"
        :animate="{ opacity: 1, scaleY: 1 }"
        :exit="{ opacity: 0, scaleY: 0 }"
        :transition="{
          duration: 0.4,
          ease: [0, 0.71, 0.2, 1.01]
        }"
        layout
      >
        <div class="v-cart-sidebar-item__image v-elements-overlay">
          <div class="v-overlay-element">
            <arora-nuxt-image
              :alt="item.Product.Name"
              :height="96"
              :image="item.Product.SmallImageURL"
              :width="96"
            />
          </div>

          <transition
            appear
            mode="out-in"
            name="fade"
          >
            <div
              class="v-overlay-element v-cart-sidebar-item__image--deleted"
              v-if="item.Removed"
            >
              <span
                class="v-typography-text-3 v-cursor-default"
                v-html="translate('sidebarContent.deletedByUser')"
              />
            </div>
          </transition>
        </div>
        <div class="v-cart-sidebar-item__right-part v-d-flex v-flex-column">
          <div
            class="v-cart-sidebar-item__title v-d-flex v-justify-content-between"
            :class="{
              'v-cart-sidebar-item__title--no-hr':
                item.Product.DescriptionType ===
                ProductDescriptionType.RemovableIngredients
                  ? removedIngredientsByProduct[item.ID].length === 0
                  : item.ProductComposition.length === 0
            }"
          >
            <div>
              <arora-nuxt-link
                v-if="item.WebsiteLink"
                :class-name="`v-typography-title-4 v-link-text-color-mono-${item.Removed ? 600 : 1000} v-link-text-color__no-underline`"
                :href="item.WebsiteLink"
                :label="sanitize(item.Name)"
              />
              <div
                v-else
                :class="`v-typography-title-4 v-cursor-default v-color-mono-${item.Removed ? 600 : 1000}`"
                v-html="sanitize(item.Name)"
              />
              <div
                v-if="item.SelectedOption"
                :class="`v-typography-text-5 v-cursor-default v-color-mono-${item.Removed ? 400 : 800}`"
                v-html="
                  sanitize(
                    `${item.SelectedOption.Name} ${item.SelectedOption.Description}`
                  )
                "
              />
              <div
                v-if="item.Product.IsCombine && item.Slots.length > 0"
                :class="`v-typography-text-5 v-cursor-default v-color-mono-${item.Removed ? 400 : 800}`"
                v-html="item.Slots.map((s) => sanitize(s.Name)).join(', ')"
              />

              <div
                v-if="modifiersWithoutIngredientsByProduct[item.ID].length > 0"
                :class="`v-typography-text-5 v-cursor-default v-color-mono-${item.Removed ? 400 : 800}`"
              >
                <span
                  v-html="translate('sidebarContent.additionalModifiersTitle')"
                />
                <span v-html="modifiersWithoutIngredientsByProduct[item.ID]" />
              </div>
              <div
                v-if="removedIngredientsByProduct[item.ID].length > 0"
                :class="`v-typography-text-5 v-cursor-default v-color-mono-${item.Removed ? 400 : 800}`"
              >
                <span
                  v-html="translate('sidebarContent.removableIngredientsPrefix')"
                />
                <span
                  v-for="(ing, ingIndex) in removedIngredientsByProduct[item.ID]"
                  :key="`${sanitize(item.ID)}-${sanitize(ing.ModifierId)}`"
                >
                  <span
                    class="v-cart-item-summary-ingredients__ingredient"
                    v-html="sanitize(ing.Title)"
                  />
                  <span
                    v-if="
                      ingIndex !== removedIngredientsByProduct[item.ID].length - 1
                    "
                    v-html="'; '"
                  />
                </span>
              </div>
            </div>

            <ui-icon
              v-if="!item.FixedCount"
              :size="24"
              color="mono400"
              class-name="v-pointer"
              name="close"
              @click="
                item.PositionType === PositionType.MultipleGift
                  ? clientStore.clearPromoCode()
                  : deleteItemLocal(item, true)
              "
            />
          </div>

          <div
            v-if="
              item.Product.DescriptionType ===
              ProductDescriptionType.RemovableIngredients
                ? removedIngredientsByProduct[item.ID].length > 0
                : item.ProductComposition.length > 0
            "
            :class="`v-typography-text-4 v-color-mono-${item.Removed ? 400 : 1000}`"
            v-html="
              item.Product.DescriptionType ===
              ProductDescriptionType.RemovableIngredients
                ? getDescriptionWithoutRemovedIngredients(
                    item,
                    removedIngredientsByProduct[item.ID]
                  )
                : sanitize(item.ProductComposition)
            "
          />

          <div
            class="v-cart-sidebar-item__hr"
            v-if="
              item.Product.DescriptionType ===
              ProductDescriptionType.RemovableIngredients
                ? removedIngredientsByProduct[item.ID].length > 0
                : item.ProductComposition.length > 0
            "
          >
            <hr />
          </div>

          <div
            :class="`v-cart-sidebar-item__prices v-d-flex v-justify-content-between v-color-mono-${item.Removed ? 600 : 1000}`"
          >
            <cart-rhudaur-content-item-price
              :product="item"
              price-class-name="v-typography-text-2"
            />
            <div class="v-elements-overlay v-cart-sidebar-item__button">
              <div
                class="v-overlay-element"
                v-if="item.Removed && item.Active"
              >
                <ui-button
                  :size="32"
                  color="primary-opacity"
                  width="wide"
                  :label="translate('sidebarContent.restore')"
                  :click-event="async () => await restoreCartItem(item.ID)"
                />
              </div>
              <div
                class="v-overlay-element"
                v-else-if="item.Removed && !item.Active"
              >
                <ui-button
                  :size="32"
                  color="primary-opacity"
                  width="wide"
                  :label="translate('sidebarContent.replace')"
                  :click-event="async () => await deleteItemLocal(item, false)"
                />
              </div>
              <div
                class="v-overlay-element"
                v-else-if="item.PriceInPoints.WithDiscount > 0"
              >
                <ui-button
                  :size="32"
                  color="primary"
                  width="wide"
                  :label="translate('sidebarContent.edit')"
                  :click-event="callForMenuPointsPopup"
                />
              </div>
              <div
                class="v-overlay-element"
                v-else-if="item.PositionType === PositionType.MultipleGift"
              >
                <ui-button
                  :size="32"
                  color="primary"
                  width="wide"
                  :label="translate('sidebarContent.edit')"
                  :click-event="callForGiftsPopup"
                />
              </div>
              <div
                class="v-overlay-element"
                v-else
              >
                <ui-stepper
                  :disabled="item.FixedCount"
                  :value="item.Count"
                  :size="32"
                  color="grey"
                  :on-decrement="async () => await onDecrement(item)"
                  :on-increment="async () => await onIncrement(item)"
                  :disable-plus-button="
                    item.PriceInPoints.WithDiscount > 0 &&
                    item.PriceInPoints.WithDiscount * item.Count >= PointsBalance
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </motion.li>
    </AnimatePresence>
  </motion.ul>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'
import type { RemovableIngredient } from '~types/menuStore'

import type { GUID } from '@arora/common'

import { motion } from 'motion-v'
import { PositionType, ProductDescriptionType } from '~api/consts'

const appConfig = useAppConfig()

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { callForGiftsPopup, deleteItem, items } = useCart()

const {
  getDescriptionWithoutRemovedIngredients,
  getModifiersWithoutIngredientsByProduct,
  getRemovedIngredientsByProduct
} = useProduct()
const { restoreCartItem, setItemCount } = useCartFunctionsWithDialogs()
const { PointsBalance } = storeToRefs(useAccountStore())
const { sanitize, translate } = useI18nSanitized()

const removedIngredientsByProduct = computed<Record<GUID, RemovableIngredient[]>>(
  () => getRemovedIngredientsByProduct(items.value)
)

async function onDecrement(item: CartItem): Promise<void> {
  await setItemCount(item.ID, item.RealCount - 1, item.GroupID, item.Name)
}

async function onIncrement(item: CartItem): Promise<void> {
  await setItemCount(item.ID, item.RealCount + 1, item.GroupID, item.Name)
}

const lockDeleteButton = ref<boolean>(false)

const modifiersWithoutIngredientsByProduct = computed<Record<GUID, string>>(() =>
  getModifiersWithoutIngredientsByProduct(
    items.value,
    removedIngredientsByProduct.value
  )
)

async function deleteItemLocal(item: CartItem, noRedirect: boolean): Promise<void> {
  lockDeleteButton.value = true
  await deleteItem(item, false)

  if (noRedirect) {
    lockDeleteButton.value = false
  } else {
    const group = appConfig.Groups.find((group) => group.ID === item.GroupID)?.Link
    navigateTo(group ?? appConfig.VueSettingsPreRun.Links.DefaultMenuGroup)
  }
}

async function callForMenuPointsPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'accountPointsMenuPopup'
  })
}
</script>

<style lang="scss">
@use '~/assets/extend';
@use '~/assets/variables';

.v-cart-sidebar-items-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.285rem;

  .v-cart-sidebar-item {
    display: flex;
    gap: 16px;
    background: variables.$White;

    &__title {
      margin-bottom: 0.285rem;

      &--no-hr {
        padding-bottom: 0.855rem;
      }
    }

    &__image {
      flex: 96px;
      max-width: 96px;
      aspect-ratio: 1/1;

      &--deleted {
        display: flex;
        justify-content: center;
        align-items: center;
        background: variables.$Mono1000Opacity50;
        color: variables.$White;
      }
    }

    &__right-part {
      flex: 0 0 calc(100% - 110px);
      max-width: calc(100% - 110px);
      padding: 0.57rem 2.145rem 0.855rem 0;
    }

    &__hr {
      padding: 0.855rem 0;
      @extend .v-no-hr-margin;
    }

    &__button {
      width: 7.855rem;
    }
  }
}
</style>
